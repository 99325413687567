/* v8 ignore start */
import _slugify from "slugify";

/**
 * Configured slugify function
 */
export function slugify(str: string) {
  return _slugify(str, {
    lower: true,
    strict: true,
  });
}
/* v8 ignore stop */
