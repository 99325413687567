<script lang="ts">
  import { PortableText } from "@portabletext/svelte";
  import type { InputValue } from "@portabletext/svelte";
  import Heading from "./lib/Heading/Heading.svelte";
  import Image from "./lib/Image.svelte";
  import Link from "./lib/Link/Link.svelte";

  type RichtextProps = { value?: InputValue };

  const { value }: RichtextProps = $props();
</script>

<PortableText
  {value}
  components={{
    types: {
      image: Image,
    },
    block: {
      h2: Heading,
    },
    marks: {
      link: Link,
    },
  }}
/>
