<script lang="ts">
  import { slugify } from "@hyperfocal/utils";
  import type { CustomBlockComponentProps } from "@portabletext/svelte";
  import linkIcon from "lucide-static/icons/link.svg?raw";
  import type { Snippet } from "svelte";

  type HeadingProps = {
    portableText: CustomBlockComponentProps;
    children: Snippet;
  };

  const { portableText, children }: HeadingProps = $props();
  const anchor = $derived(slugify(portableText.value.children[0].text));
</script>

<style>
  h2 {
    display: flex;
    align-items: center;
  }

  .anchor {
    display: inline-block;
    transition: opacity 150ms var(--ease-standard);
    opacity: 0;
    text-decoration: none;
    margin-left: var(--size-3);
    transform: translateY(10%);
    & :global(svg) {
      display: block;
      width: 0.7em;
      height: 0.7em;
      stroke: var(--color-grey-400);
    }
  }

  h2:hover .anchor {
    opacity: 1;
  }
</style>

<h2 id={anchor}>
  {@render children()}
  <a href="#{anchor}" class="anchor">{@html linkIcon}</a>
</h2>
