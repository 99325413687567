<script lang="ts">
  import { imgProps } from "$src/lib/sanity";
  import type { CustomBlockComponentProps } from "@portabletext/svelte";

  type ImageProps = {
    portableText: CustomBlockComponentProps;
  };

  const { portableText }: ImageProps = $props();
</script>

<img {...imgProps(portableText.value.asset)} />
